<template>
  <div class="row mb-3">
    <label class="col-sm-2 col-form-label text-end">{{ label }}:</label>
    <div class="col-sm-10">
      <input class="form-control" :value="value" @input="$emit('input', $event.target.value)"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockTextInput",
  props: {
    label: {type: String},
    value: {type: String},
  }
}
</script>
