<template>
  <div class="row mb-3">
    <label class="col-sm-2 col-form-label text-end">{{ label }}:</label>
    <div class="col-sm-10">
      <BlockTextInput label="title" v-model="value.title"/>
      <div class="row mb-3">
        <label class="col-sm-2 col-form-label text-end">items:</label>
        <div class="col-sm-10">
          <draggable :list="value.items">
            <transition-group>
              <BlockBlogItem v-for="(item, index) of value.items" :key="`key-${index}`" :value="item" @remove="remove(index)"/>
            </transition-group>
          </draggable>
          <input v-show="!value.items.length" readonly class="form-control-plaintext" value="[Пусто]">
          <div class="text-end">
            <button class="btn btn-outline-secondary" @click="$emit('append')">+</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import BlockTextInput from "./BlockTextInput";
import BlockBlogItem from "./BlockBlogItem";

export default {
  name: "BlockBlog",
  components: {
    draggable,
    BlockTextInput,
    BlockBlogItem
  },
  props: {
    label: {type: String},
    value: {type: Object},
  },
  methods: {
    remove(index) {
      if (confirm('Remove?')) {
        this.value.items.splice(index, 1)
      }
    }
  }
}
</script>
