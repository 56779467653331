<template>
  <div class="mb-3 p-3 border rounded shadow-sm">
    <div class="d-flex gap-3">
      <div class="w-100">
        <BlockTextInput label="info" v-model="value.info"/>
        <BlockTextInput label="description" v-model="value.description"/>
        <BlockFile label="image" v-model="value.image"/>
        <BlockThemes v-model="value.themes" @append="append(value.themes)"/>
      </div>
      <div class="flex-shrink-1">
        <button class="btn btn-outline-secondary" @click="$emit('remove')">&minus;</button>
      </div>
    </div>
  </div>
</template>

<script>
import BlockFile from "./BlockFile"
import BlockTextInput from "./BlockTextInput";
import BlockThemes from "./BlockThemes"

export default {
  name: "BlockBlogItem",
  components: {
    BlockFile,
    BlockTextInput,
    BlockThemes,
  },
  props: {
    value: {type: Object},
  },
  methods: {
    append(node) {
      node.push({
        value: ""
      })
    }
  }
}
</script>
