<template>
  <div class="row">
    <label class="col-sm-2 col-form-label text-end">themes:</label>
    <div class="col-sm-10">
      <draggable :list="value">
        <transition-group>
          <BlockThemesItem
            label="value"
            v-for="(item, index) of value"
            :key="index"
            v-model="value[index]"
            @remove="remove(index)"
          />
        </transition-group>
      </draggable>
      <input v-show="!value.length" readonly class="form-control-plaintext" value="[Пусто]">
      <div class="text-end">
        <button class="btn btn-outline-secondary" @click="$emit('append')">+</button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import BlockThemesItem from "./BlockThemesItem"

export default {
  name: "BlockThemes",
  components: {
    draggable,
    BlockThemesItem
  },
  props: {
    value: {type: Array},
  },
  methods: {
    remove(index) {
      if (confirm('Remove?')) {
        this.value.splice(index, 1)
      }
    }
  }
}
</script>
