<template>
  <div class="mb-3 p-3 border rounded shadow-sm">
    <div class="d-flex gap-3">
      <div class="w-100">
        <BlockTextInput :label="itemFieldsLabels.title" v-model="value.title"/>
        <BlockTextInput :label="itemFieldsLabels.description" v-model="value.description"/>
        <BlockFile :label="itemFieldsLabels.image" v-model="value.image"/>
      </div>
      <div class="flex-shrink-1">
        <button class="btn btn-outline-secondary" @click="$emit('remove')">&minus;</button>
      </div>
    </div>
  </div>
</template>

<script>
import BlockFile from "./BlockFile";
import BlockTextInput from "./BlockTextInput";

export default {
  name: "BlockTeamsItem",
  components: {
    BlockFile,
    BlockTextInput,
  },
  props: {
    label: {type: String},
    itemFieldsLabels: {type: Object},
    value: {type: Object},
  }
}
</script>
