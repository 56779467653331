<template>
  <div>
    <h1 class="text-center m-4">BlockEditor</h1>
    <div class="text-center m-4">
      <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
        <input type="radio" class="btn-check" value="editor" id="btnradio1" autocomplete="off" v-model="tab">
        <label class="btn btn-outline-secondary" for="btnradio1">Editor</label>
        <input type="radio" class="btn-check" value="preview" id="btnradio2" autocomplete="off" v-model="tab">
        <label class="btn btn-outline-secondary" for="btnradio2">Preview</label>
      </div>
    </div>
    <div class="mb-4 p-4 border rounded-3 shadow-sm bg-white" v-show="tab === 'editor'">
      <div v-for="(block, index) in blocks" :key="index">
        <component
          :is="block.component"
          :label="block.label"
          :itemFieldsLabels="block.itemFieldsLabels"
          v-model="block.value"
          @append="append(block.component, block.value)"
        />
      </div>
    </div>
    <div class="mb-4 p-4 border rounded-3 shadow-sm bg-white" v-show="tab === 'preview'">
      <pre>{{ blocks }}</pre>
    </div>
  </div>
</template>

<script>
import BlockTextInput from "./BlockTextInput";
import BlockTeams from "./BlockTeams";
import BlockBlog from "./BlockBlog";

export default {
  name: "BlockEditor",
  components: {
    BlockTextInput,
    BlockTeams,
    BlockBlog,
  },
  props: {
    initData: {type: Object}
  },
  data() {
    return {
      tab: 'editor',
      blocks: {
        description: {
          component: "BlockTextInput",
          label: "DESCRIPTION",
          value: "aslkjdhflaksdhf"
        },
        teams: {
          component: "BlockTeams",
          label: "TEAMS",
          itemFieldsLabels: {
            title: 'Заголовок',
            description: 'Описание',
            image: 'Картинка',
          },
          value: [
            {
              title: "ttt",
              description: "ddd",
              image: "image",
            },
            {
              title: "ttt222",
              description: "ddd222",
              image: "image222",
            },
          ],
        },
        blog: {
          component: "BlockBlog",
          label: "BLOG",
          value: {
            title: 'blog title',
            items: [
              {
                info: "blog info",
                description: "blog desc",
                image: "blog image",
                themes: [
                  {
                    value: "Theme 1",
                  },
                  {
                    value: "Theme 2",
                  },
                ],
              },
              {
                info: "blog info",
                description: "blog desc",
                image: "blog image",
                themes: [
                  {
                    value: "Theme 1",
                  },
                  {
                    value: "Theme 2",
                  },
                ],
              },
            ]
          },
        },
      }
    }
  },
  created() {
    this.blocks = this.initData
  },
  methods: {
    append(component, node) {
      switch (component) {
        case 'BlockTeams':
          node.push({
            title: "",
            description: "",
            image: "",
          })
          break

        case 'BlockBlog':
          node.items.push({
            info: "",
            description: "",
            image: "",
            themes: [],
          })
          break
      }
    }
  }
}
</script>
