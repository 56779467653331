<template>
  <div id="app" class="container">
    <BlockEditor :init-data="data"/>
  </div>
</template>

<script>
import BlockEditor from "./components/BlockEditor"
import blocks from "./blocks.json"

export default {
  name: 'App',
  components: {
    BlockEditor
  },
  created() {
    this.data = blocks
  }
}
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.1.3/css/bootstrap.min.css";

body {
  background: #eee;
}
</style>
