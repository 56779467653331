<template>
  <div class="row mb-3">
    <label class="col-sm-2 col-form-label text-end">{{ label }}:</label>
    <div class="col-sm-10">
      <draggable :list="value">
        <transition-group>
          <BlockTeamsItem
            v-for="(item, index) of value"
            :key="index"
            :value="item"
            :itemFieldsLabels="itemFieldsLabels"
            @remove="remove(index)"
          />
        </transition-group>
      </draggable>
      <input v-show="!value.length" readonly class="form-control-plaintext" value="[Пусто]">
      <div class="text-end">
        <button class="btn btn-outline-secondary" @click="$emit('append')">+</button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import BlockTeamsItem from "./BlockTeamsItem";

export default {
  name: "BlockTeams",
  components: {
    draggable,
    BlockTeamsItem,
  },
  props: {
    label: {type: String},
    itemFieldsLabels: {type: Object},
    value: {type: Array},
  },
  methods: {
    remove(index) {
      if (confirm('Remove?')) {
        this.value.splice(index, 1)
      }
    }
  }
}
</script>
