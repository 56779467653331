<template>
  <div class="d-flex mb-3 gap-2">
    <div class="w-100">
      <input class="form-control" v-model="value.value"/>
    </div>
    <div class="flex-shrink-1">
      <button class="btn btn-outline-secondary" @click="$emit('remove')">&minus;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockThemesItem",
  props: {
    label: {type: String},
    value: {type: Object}
  }
}
</script>
